import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// Components
import Homepage from "./components/Homepage/Homepage";
import Navbar from './components/Layout/Navbar';
import Footer from './components/Layout/Footer';
import OurSolutions from './components/SolutionLandingPage/OurSolutions';
import ContactUs from './components/ContactUs/ContactUs';
import SmartHomePage from './components/SmartHomeSystem/SmartHomePage';
import LightCurrentPage from './components/LightCurrentSystem/LightCurrentPage';
import AboutUsPage from './components/AboutUs/AboutUsPage';
import NotFound from './components/NotFound/NotFound';
import ScrollToTop from './components/ScrollToTop';
import Blog from './components/Blog/Blog';
import SmartBuildingSystem from './components/SmartBuildingSystem/SmartBuildingSystem';
import { HelmetProvider } from 'react-helmet-async';
const helmetContext = {};

function App() {
  const { t } = useTranslation();

  return (
    <div>
    <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route index path={t('website_links.homepage')} element={<Homepage />} />
            <Route path={t('website_links.our_solutions')} element={<OurSolutions />} />
            <Route path={t('website_links.contact_us')} element={<ContactUs />} />
            <Route path={t('website_links.smart_home')} element={<SmartHomePage />} />
            <Route path={t('website_links.smart_building')} element={<SmartBuildingSystem />} />
            <Route path={t('website_links.light_current')} element={<LightCurrentPage />} />
            <Route path={t('website_links.about_us')} element={<AboutUsPage />} />
            <Route path={t('website_links.blog')} element={<Blog />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
          <ScrollToTop />
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;